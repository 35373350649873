import React, { useEffect, useState } from 'react';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import Card from "@mui/material/Card";
import { CardContent, Typography, LinearProgress, Grid, Stack, List, ListItem, ListItemText, IconButton, ButtonGroup, Divider, Button, Alert, Autocomplete, TextField, Box } from "@mui/material";
import Div from "@jumbo/shared/Div";
import _ from 'lodash';
import moment from 'moment';
import FilePresentOutlinedIcon from '@mui/icons-material/FilePresentOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import UnfoldMoreOutlinedIcon from '@mui/icons-material/UnfoldMoreOutlined';
import UnfoldLessOutlinedIcon from '@mui/icons-material/UnfoldLessOutlined';

import StateBadge from '../../widgets/Shared/StateBadge/StateBadge';
import { stateInfo } from '../../widgets/Shared/StateBadge/stateInfo';
import CalendarMonthTwoToneIcon from '@mui/icons-material/CalendarMonthTwoTone';
import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import StoreTwoToneIcon from '@mui/icons-material/StoreTwoTone';
import EmojiTransportationTwoToneIcon from '@mui/icons-material/EmojiTransportationTwoTone';
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import PhonelinkEraseOutlinedIcon from '@mui/icons-material/PhonelinkEraseOutlined';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import MoreTimeOutlinedIcon from '@mui/icons-material/MoreTimeOutlined';
import LockClockOutlinedIcon from '@mui/icons-material/LockClockOutlined';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import PlaceIcon from '@mui/icons-material/Place';
import ImagesDownloadBtn from '../../widgets/Images/ImagesDownloadBtn';
import centreServices from 'app/services/centre-services';
import tripServices from 'app/services/trip-services';
import deliveryServices from 'app/services/delivery-services';
import { vehicleIcon } from 'app/utils/appHelpers';

const TripListCard = ({ trip, onTripFocus, viewMoreActive, onViewMore, onDeliveryFocus, onDriverEdit, onScheduledTimeEdit, onDocsImagesView, onTripChange, onDeliveryChange }) => {
  const { authUser } = useJumboAuth();
  const [vehicles, setVehicles] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [editingDriver, setEditingDriver] = useState(false);
  const [tripState, setTripState] = useState(trip.state);
  const [stateName, setStateName] = useState(trip.state_name);
  const [editingVehicle, setEditingVehicle] = useState(false);
  const [selDriver, setSelDriver] = useState(trip.driver);
  const [selVehicle, setSelVehicle] = useState(trip.vehicle);
  const [isFieldEditing, setIsFieldEditing] = useState(false);
  const [observations, setObservations] = useState({});
  const [updatedDelivery, setUpdatedDelivery] = useState(null);

  let inputDriverRef;
  let inputVehicleRef;

  useEffect(() => {
      if (authUser) {
        //   console.log(authUser.current_company);
      } else {
          console.log('authUser is not defined yet.');
      }
  }, [authUser]);

  useEffect(() => {
      moment.locale('es');
  }, []);

  useEffect(() => {
    if (trip.state === 'pending' && authUser?.current_company?.id === 80) {
        setTripState('in_progress');
        setStateName('En progreso');
    }
    }, [trip, authUser]);

  useEffect(() => {
      if (editingVehicle) {
          inputVehicleRef.focus();
          if (vehicles.length <= 0) {
              updateOptions();
          }
      } else {
          if (selVehicle && selVehicle.id !== trip.vehicle?.id) {
              updateTrip(trip.id, { vehicle_id: selVehicle.id });
          }
      }
  }, [editingVehicle]);

  useEffect(() => {
      if (editingDriver) {
          inputDriverRef.focus();
          if (drivers.length <= 0) {
              updateOptions();
          }
      } else {
          if (selDriver && selDriver.id !== trip.driver?.id) {
              updateTrip(trip.id, { driver_id: selDriver.id });
          }
      }
  }, [editingDriver]);

  useEffect(() => {
      // Initialize observations with existing values
      const initialObservations = {};
      trip.deliveries.forEach((d) => {
          initialObservations[d.id] = d.receipt?.observations || '';
      });
      setObservations(initialObservations);
  }, [trip.deliveries]);

  const toggleEditing = (id) => {
    setIsFieldEditing((prev) => !prev);
  };

  const updateOptions = () => {
      if (trip.centre?.id) {
          centreServices.getCentreOptions(trip.centre?.id)
              .then(data => {
                  setDrivers(data?.data?.drivers);
                  setVehicles(data?.data?.vehicles);
              });
      }
  };

  const updateTrip = (trip_id, params) => {
      tripServices.updateTrip(trip_id, "?include=driver,vehicle.company,vehicle.transport_unit1,vehicle.transport_unit2", params)
          .then(data => {
              onTripChange(data);
          });
  };

    const handleOcChange = (id, value) => {
        setObservations((prev) => ({
        ...prev,
        [id]: value,
        }));
    };

  const saveOcChange = (deliveryId, receiptId) => {
      deliveryServices.updateReceipt(deliveryId, { observations: observations[deliveryId] })
          .then(updatedDelivery => {

              const updatedDeliveries = trip.deliveries.map(d =>
                  d.id === updatedDelivery.id ? { ...d, receipt: updatedDelivery.receipt } : d
              );

              const updatedTrip = { ...trip, deliveries: updatedDeliveries };
              onTripChange(updatedTrip);
              onDeliveryChange(updatedDelivery);  // This is to update the delivery in the parent component
          })
          .catch(error => {
              console.error('Failed to update delivery:', error);
          });
  };

  const OnReceptionFocus = (d) => {
  }
  const preOnDeliveryFocus = (d) => {
    // Manually update the observations for the delivery object
    const updatedObservations = observations[d.id] ?? d.receipt?.observations ?? '';
    const updatedReceipt = { ...d.receipt, observations: updatedObservations };
    const updatedDelivery = { ...d, receipt: updatedReceipt };
  
    onDeliveryFocus(updatedDelivery);
  };
  
  const formatTin = (tin) => {
    if (!tin) return '';
  
    // Remove any dots, hyphens, or spaces
    const cleanedTin = tin.replace(/[.\-\s]/g, '');
  
    const tinWithoutHyphen = cleanedTin.slice(0, -1);
    const formattedTin = tinWithoutHyphen.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    const lastChar = cleanedTin.slice(-1);
  
    return `${formattedTin}-${lastChar}`.toUpperCase();
  };

  const waterColor = trip.metadata?.water_ok ? 'text.secondary' : 'error'; // Assuming 'error' maps to red or tertiary color in your theme

  const renderDeliveries = () => {
    
      if (trip.deliveries) {
          return (
              <List
                  dense={true}
                  style={{ maxHeight: viewMoreActive ? '500px' : '160px', overflow: 'auto', transitionDuration: '0.3s' }}
                  sx={{ padding: 0 }}
              >
                  {_.map(trip.deliveries, (d) => {
                    const formattedTin = formatTin(d.receipt?.tin);
                      return (
                          <ListItem
                              key={`delivery-${d.id}`} // Add a unique key
                              alignItems="flex-start"
                              id={d.id}
                          >
                              <ListItemText
                                  id={d.id}
                                  primary={
                                      <Stack direction="row" alignItems="center" spacing={1} sx={{ maxWidth: 400 }}>
                                          <PlaceIcon color={d.locatable?.has_location ? 'success' : 'error'} sx={{ fontSize: 14 }} />
                                          <Typography  variant="body2" sx={{ display: 'inline', fontWeight: 350 }} color="#2EB5C9" >
                                              {d.code} {d.locatable.name}
                                          </Typography>
                                          {d.reception_msg ? <WarningAmberRoundedIcon color={d.reception_msg.kind} /> : null}
                                      </Stack>
                                  }
                                  secondary={
                                      <React.Fragment>
                                          {authUser?.current_company?.id !== 80 && (
                                              <>
                                                  <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={1}>
                                                      <Typography variant="body2" sx={{ textAlign: 'left' }} color="text.secondary">
                                                          Ped: {d.order?.code}
                                                      </Typography>
                                                      {_.map(d.delivery_docs, (dd) => (
                                                          <Typography key={`doc-${dd.id}`} variant="body2" sx={{ textAlign: 'left' }} color="text.secondary">
                                                              {dd.shipping_doc?.shipping_doc_kind?.slug === 'delivery-receipt' ? 'Guía: ' : 'Fact: '}
                                                              {dd.shipping_doc?.customer_code}
                                                          </Typography>
                                                      ))}
                                                  </Stack>
                                                  <Stack direction="row" alignItems="center" spacing={1}>
                                                      <Typography variant="body2" color="text.secondary">
                                                          {d.planned_start && d.planned_end
                                                              ? `P: ${moment(d.planned_end).format('DD MMM HH:mm')} (${printTimeDiff(
                                                                  d.planned_start,
                                                                  d.planned_end
                                                              )})${d.final_end ? ` - R: ${moment(d.final_end).format('DD MMM HH:mm')}` : ''}`
                                                              : 'Sin planificación'}
                                                      </Typography>
                                                      {renderTimeWindow(d)}
                                                  </Stack>
                                                  {d.order?.comment && (
                                                      <Alert
                                                          severity="info"
                                                          icon={<CommentOutlinedIcon fontSize="small" />}
                                                          sx={{
                                                              '&.MuiAlert-root': {
                                                                  display: 'inline-flex',
                                                                  px: 0.5,
                                                                  py: 0,
                                                                  fontSize: 11,
                                                              },
                                                          }}
                                                      >
                                                          {d.order.comment}
                                                      </Alert>
                                                  )}
                                              </>
                                          )}
                                          {authUser?.current_company?.id === 80 && (
                                              <>
                                                  <Typography variant="body2" sx={{ ml: 2.675, textAlign: 'left', mt: -0.5 }} color="text.secondary">
                                                      {d.locatable?.alias}
                                                  </Typography>

                                                  <Stack direction="row" alignItems="center" spacing={1}>
                                                    <Typography sx={{ ml: 1 }} variant="body2" color="text.secondary">
                                                        {d.receipt?.completed_at ? (
                                                            <>
                                                                Entregado:&nbsp;&nbsp;&nbsp;{moment(d.receipt.completed_at).format('DD MMM HH:mm')}
                                                            </>
                                                        ) : (
                                                            'Sin planificación'
                                                        )}
                                                    </Typography>
                                                    {renderTimeWindow(d)}
                                                </Stack>
                                                  <Stack direction="row" alignItems="center" spacing={1}>
                                                      <LocalGasStationIcon fontSize="small" sx={{ ml: 0.475, color: '#2EB5C9' }} />
                                                      <Typography variant="body2" sx={{ ml: 1 }} color="text.secondary">
                                                          {d.delivery_details?.[0]?.product ? `${d.delivery_details[0].product} ` : ''}{d.delivery_details?.[0]?.delivered_qty?.toLocaleString()} L
                                                      </Typography>
                                                  </Stack>
                                                  <Stack direction="row" alignItems="center" spacing={1}>
                                                      <WaterDropIcon fontSize="small" sx={{ ml: 0.375, color: '#2EB5C9' }} />
                                                      <Typography variant="body2" sx={{ ml: 1 }} color="text.secondary">
                                                          {d.delivery_details?.[0]?.observations ? `${d.delivery_details[0].observations} ` : ''}
                                                      </Typography>
                                                  </Stack>
                                                  <Stack direction="row" alignItems="center" spacing={1}>
                                                        <PersonOutlineTwoToneIcon fontSize="small" sx={{ ml: 0.375, color: '#2EB5C9' }} />
                                                        <Typography variant="body2" sx={{ ml: 1 }} color="text.secondary">
                                                        {formattedTin ? `${formattedTin} ` : ''}{d.receipt?.name ? `${d.receipt.name}` : ''}
                                                        </Typography>
                                                  </Stack>
                                                  <Box key={`receipt-${d.id}`} sx={{ display: 'flex', alignItems: 'center', mt: -0.8 }}>
                                                      <ReceiptLongOutlinedIcon
                                                          fontSize="small"
                                                          sx={{ ml: 0.265, color: '#2EB5C9', display: 'flex', alignItems: 'center' }} />
                                                      <TextField
                                                          variant="standard"
                                                          size="small"
                                                          value={observations[d.id] ?? d.receipt?.observations ?? ''}
                                                          onChange={(e) => handleOcChange(d.id, e.target.value)}
                                                          InputProps={{
                                                              disableUnderline: true,
                                                          }}
                                                          sx={{ width: '100px', ml: 1.375, mt: 0.5 }}
                                                          disabled={!isFieldEditing} />
                                                      <Button
                                                          onClick={() => {
                                                              if (isFieldEditing) {
                                                                  saveOcChange(d.id, d.receipt?.id);
                                                              }
                                                              toggleEditing(d.id);
                                                          }}
                                                          sx={{ ml: 1, fontSize: '10px' }}
                                                      >
                                                          {isFieldEditing ? 'Guardar' : 'Editar'}
                                                      </Button>
                                                  </Box>
                                              </>
                                          )}
                                      </React.Fragment>
                                  }
                              />
                              <Stack
                                  direction="row"
                                  justifyContent="flex-end"
                                  alignItems="center"
                                  spacing={0.5}>
                                  <StateBadge state={d.state} stateName={d.state_name} withIcon={false} />
                                  <ButtonGroup variant="outlined" aria-label="outlined button group">
                                      <IconButton edge="end" aria-label="Documentos tributarios" onClick={() => onDocsImagesView(d)} disabled={d.docs_images_count <= 0}>
                                          <FilePresentOutlinedIcon fontSize="small" color={d.docs_images_count > 0 ? 'success' : 'disabled'} />
                                      </IconButton>
                                      <IconButton edge="end" aria-label="Detalle de entregas" onClick={() => preOnDeliveryFocus(d)}>
                                          <FactCheckOutlinedIcon fontSize="small" color={d.receipt ? 'success' : 'disabled'} />
                                      </IconButton>
                                  </ButtonGroup>
                              </Stack>
                          </ListItem>
                      );
                  })}
              </List>
          );
      }
      return null;
  };

  const printTimeDiff = (_start, _end) => {
      _start = moment(_start);
      _end = moment(_end);
      const _diffMins = _start > _end ? _start.diff(_end, 'minutes') : _end.diff(_start, 'minutes');
      const hhs = _.floor(_diffMins / 60);
      const mms = _diffMins % 60;
      return `${_start > _end ? '-' : ''}${hhs > 0 ? `${hhs}h y ` : ''}${mms}min`;
  };

  const renderTimeWindow = (del) => {
      if (del.preferred_arrival_start || del.preferred_arrival_end) {
          return (
              <Button variant="outlined" disabled={del.state === 'finished'} size="small" color={del.meets_preferred_time_window === true ? 'success' : 'error'} sx={{ p: 0 }} onClick={() => onScheduledTimeEdit(del)} >
                  <LockClockOutlinedIcon fontSize='small' />{del.preferred_arrival_start}-{del.preferred_arrival_end}
              </Button>
          );
      } else {
          if (del.state !== 'finished') {
              return (
                  <Button size="small" sx={{ p: 0 }} onClick={() => onScheduledTimeEdit(del)}>
                      <MoreTimeOutlinedIcon fontSize='small' />
                  </Button>
              );
          }
      }
  };

  return (
      <Card key={trip.id} sx={{ mb: 2 }}>
          <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>
                  <Grid item xs={12} md={3} py={0}>
                      <Typography variant="h4" color="primary">Nº {trip.code}
                      {authUser?.current_company?.id == 80 && (
                            <IconButton sx={{ pb: 1.4 }} edge="end" aria-label="Detalle de recepción" onClick={() => OnReceptionFocus()}>
                                <FactCheckOutlinedIcon fontSize="small" color={trip.code ? 'success' : 'disabled'} />
                            </IconButton>
                        )}
                        </Typography>
                      <Div sx={{ display: 'flex', alignItems: 'center', mb: 0 }}>
                          <CalendarMonthTwoToneIcon fontSize="small" color={"primary"} />
                          <Typography variant="body2" color={"text.secondary"} ml={1}>{moment(trip.ship_date).format('DD MMM')}</Typography>
                      </Div>
                      <Div sx={{ display: 'flex', alignItems: 'center', mb: 0 }}>
                          <StoreTwoToneIcon fontSize="small" color={"primary"} />
                          <Typography variant="body2" color="text.secondary" ml={1}>
                            {trip.centre?.code ? `${trip.centre?.code} - ${trip.centre?.name}` : trip.centre?.name}
                          </Typography>
                      </Div>
                      <Div sx={{ display: 'flex', alignItems: 'center', mb: 0 }}>
                          {vehicleIcon(trip.vehicle)}
                          {editingVehicle ?
                              <Autocomplete
                                  id="centre-select"
                                  size="small"
                                  freeSolo
                                  blurOnSelect
                                  fullWidth
                                  openOnFocus
                                  sx={{ pl: 1, maxWidth: 200 }}
                                  options={vehicles}
                                  getOptionLabel={(option) => `${option.code ? option.code : "S/C"} - ${option.transport_unit1?.ppu}${option.transport_unit2 ? `/${option.transport_unit2.ppu}` : ''}`}
                                  value={trip.vehicle}
                                  onChange={(e, v) => { setSelVehicle(v); }}
                                  onBlur={(e, v) => { setEditingVehicle(false); }}
                                  renderInput={(params) => <TextField {...params} variant="standard" label="Vehículo" sx={{ background: 'white' }} inputRef={input => { inputVehicleRef = input; }} />}
                              />
                              :
                              <React.Fragment>
                                  <Typography variant="body2" color={"text.secondary"} ml={1}>{trip.vehicle?.transport_unit1?.ppu} {trip.vehicle?.transport_unit2 && `/ ${trip.vehicle?.transport_unit2?.ppu}`}</Typography>
                                  {_.includes(['planned', 'pending'], trip.state) &&
                                      <IconButton edge="end" sx={{ p: 0 }} onClick={() => { setEditingVehicle(true); }}>
                                          <EditOutlinedIcon fontSize="small" color={"disabled"} />
                                      </IconButton>
                                  }
                              </React.Fragment>
                          }
                      </Div>
                      <Div sx={{ display: 'flex', alignItems: 'start', mb: 0 }}>
                          <PersonOutlineTwoToneIcon fontSize="small" color={"primary"} />
                          {editingDriver ?
                              <Autocomplete
                                  id="centre-select"
                                  size="small"
                                  freeSolo
                                  blurOnSelect
                                  fullWidth
                                  openOnFocus
                                  sx={{ pl: 1, maxWidth: 200 }}
                                  options={drivers}
                                  getOptionLabel={(option) => `${option.code ? option.code : "S/C"} - ${option.first_name}${option.last_name ? ` ${option.last_name}` : ''}`}
                                  value={trip.driver}
                                  onChange={(e, v) => { setSelDriver(v); }}
                                  onBlur={(e, v) => { setEditingDriver(false); }}
                                  renderInput={(params) => <TextField {...params} variant="standard" label="Conductor" sx={{ background: 'white' }} inputRef={input => { inputDriverRef = input; }} />}
                              />
                              :
                              <React.Fragment>
                                  <Typography variant="body2" color={"text.secondary"} ml={1}>{trip.driver?.first_name}{trip.driver?.last_name && ` ${trip.driver?.last_name}`}</Typography>
                                  {_.includes(['planned', 'pending'], trip.state) &&
                                      <IconButton edge="end" sx={{ p: 0 }} onClick={() => { setEditingDriver(true); }}>
                                          <EditOutlinedIcon fontSize="small" color={"disabled"} />
                                      </IconButton>
                                  }
                              </React.Fragment>
                          }
                      </Div>
                      <Div sx={{ display: 'flex', alignItems: 'start', mb: 0 }}>
                          <EmojiTransportationTwoToneIcon fontSize="small" color={"primary"} />
                          <Typography variant="body2" color={"text.secondary"} ml={1}>{trip.transportation_company}</Typography>
                      </Div>
                      {authUser?.current_company?.id === 80 && (
                          <Div sx={{ display: 'flex', alignItems: 'center', mb: 0 }}>
                              <WaterDropIcon fontSize="small" color={"primary"} />
                              <Typography variant="body2" color={waterColor} ml={1}>{trip.metadata?.water_test}</Typography>
                          </Div>
                      )}
                      <Div sx={{ mt: 1 }}>
                             <StateBadge state={tripState} stateName={stateName} withIcon={true} />
                      </Div>
                      <Grid container spacing={2}>
                          <Grid item xs={12} sm={8} md={8} lg={8} xl={8} py={0}>
                              <Div sx={{ display: 'flex', alignItems: 'center' }}>
                                  <Grid container spacing={2} alignItems="center">
                                      <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                          <Div sx={{ width: '100%', mr: 1 }}>
                                              <LinearProgress
                                                  variant="determinate"
                                                  color={stateInfo(trip.state).color}
                                                  value={(trip.deliveries_ended / trip.deliveries_total) * 100}
                                              />
                                          </Div>
                                      </Grid>
                                      <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                          <Div sx={{ minWidth: 35 }}>
                                              <Typography variant="body2" color="text.secondary">
                                                  {`${trip.deliveries_ended}/${trip.deliveries_total}`}
                                              </Typography>
                                          </Div>
                                      </Grid>
                                  </Grid>
                              </Div>
                              {authUser?.current_company?.id === 80 && (
                                  <Div sx={{ display: 'flex', alignItems: 'center', mb: 0 }}>
                                      <Grid container spacing={2} alignItems="center">
                                          <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                              <Div sx={{ width: '100%', mr: 1 }}>
                                                  <LinearProgress
                                                      variant="determinate"
                                                      value={(trip.pending_qty / trip.max_load_volume) * 100}
                                                      sx={{
                                                          backgroundColor: '#d8cfcf', // A lighter shade of #322C2B
                                                          '& .MuiLinearProgress-bar': {
                                                              backgroundColor: '#5b5655',
                                                          },
                                                      }}
                                                  />
                                              </Div>
                                          </Grid>
                                          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                              <Div sx={{ minWidth: 35 }}>
                                                  <Typography variant="body2" color="text.secondary" sx={{ whiteSpace: 'nowrap' }}>
                                                      {`${trip.pending_qty.toLocaleString()}/${trip.max_load_volume.toLocaleString()} L`}
                                                  </Typography>
                                              </Div>
                                          </Grid>
                                      </Grid>
                                  </Div>
                              )}
                          </Grid>
                      </Grid>

                  </Grid>

                  <Grid item xs={12} md={6}>
                      {renderDeliveries()}
                  </Grid>

                  <Grid item xs={12} md={3} sx={{ textAlign: 'right' }} pr={1}>
                      <IconButton edge="end" onClick={() => onTripFocus(trip)} disabled={trip.state === 'planned'}>
                          <MapOutlinedIcon color={_.get(trip, 'deliveries[0].has_route') === true ? 'success' : 'disabled'} />
                      </IconButton>
                      {authUser?.current_company?.id !== 80 && (
                        <>
                            <IconButton edge="end" onClick={() => onDriverEdit(trip)}>
                                {(trip?.driver?.phone_primary || trip?.driver?.phone_seondary) ?
                                    <PhoneIphoneOutlinedIcon color='success' />
                                    :
                                    <PhonelinkEraseOutlinedIcon color='error' />
                                }
                            </IconButton>
                        </>
                      )}
                      <ImagesDownloadBtn fieldIdentifiers="trip.deliveries.delivery_details.images" resourceIds={trip.id} disabled={_.sumBy(trip.deliveries, (d) => { return (d.docs_images_count + d.images_count) }) <= 0} />
                      <IconButton edge="end" onClick={() => onViewMore(trip)}>
                          {viewMoreActive ? <UnfoldLessOutlinedIcon /> : <UnfoldMoreOutlinedIcon />}
                      </IconButton>
                  </Grid>
              </Grid>
          </CardContent>
      </Card>
  );
};

export default TripListCard;
