import React from "react";
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import PersonPinCircleOutlinedIcon from '@mui/icons-material/PersonPinCircleOutlined';
import FeaturedPlayListOutlinedIcon from '@mui/icons-material/FeaturedPlayListOutlined';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import GroupWorkOutlinedIcon from '@mui/icons-material/GroupWorkOutlined';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import DirectionsBoatTwoToneIcon from '@mui/icons-material/DirectionsBoatTwoTone';
import DashboardOutlinedIcon from '@mui/icons-material/Dashboard'; 
import _ from "lodash";

const menus = (user) => {
    var out = [
        {
            label: 'sidebar.menu.home',
            type: "section",
            children: []
        },
    ];

    const hasProfile = (profileName) => _.includes(_.map(user.profiles, 'name'), profileName);

    if (user) {
        const isAdminAndDriver = hasProfile('customer_admin') && hasProfile('driver');
        const isSupervisorAndDriver = hasProfile('supervisor') && hasProfile('driver');
        
        if (isAdminAndDriver) {
            out[0].children.push({
                uri: "/trips",
                label: 'sidebar.menuItem.trips',
                type: "nav-item",
                icon: user.current_company?.id === 80 ? <DirectionsBoatTwoToneIcon fontSize="small" color={"primary"} /> : <LocalShippingOutlinedIcon sx={{fontSize: 20}}/>
            }, {
                uri: "/customers",
                label: 'sidebar.menuItem.customers',
                type: "nav-item",
                icon: <PersonPinCircleOutlinedIcon sx={{fontSize: 20}}/>
            }, {
                uri: "/dashboard",
                label: 'sidebar.menuItem.dashboard',
                type: "nav-item",
                icon: <DashboardOutlinedIcon sx={{fontSize: 20}}/>
            }, {
                uri: "/vehicles",
                label: user.current_company?.id === 80 ? 'sidebar.menuItem.barges' : 'sidebar.menuItem.vehicles',
                type: "nav-item",
                icon: user.current_company?.id === 80 ? <DirectionsBoatTwoToneIcon fontSize="small" color={"primary"} /> : <LocalShippingOutlinedIcon sx={{fontSize: 20}}/>
            }, {
                uri: "/users",
                label: 'sidebar.menuItem.users',
                type: "nav-item",
                icon: <PeopleAltOutlinedIcon sx={{fontSize: 20}}/>
            });
        } else if (isSupervisorAndDriver) { 
            out[0].children.push({
                uri: "/trips",
                label: 'sidebar.menuItem.trips',
                type: "nav-item",
                icon: user.current_company?.id === 80 ? <DirectionsBoatTwoToneIcon fontSize="small" color={"primary"} /> : <LocalShippingOutlinedIcon sx={{fontSize: 20}}/>
            }, {
                uri: "/customers",
                label: 'sidebar.menuItem.customers',
                type: "nav-item",
                icon: <PersonPinCircleOutlinedIcon sx={{fontSize: 20}}/>
            }, {
                uri: "/dashboard",
                label: 'sidebar.menuItem.dashboard',
                type: "nav-item",
                icon: <DashboardOutlinedIcon sx={{fontSize: 20}}/>
            }, {
                uri: "/vehicles",
                label: user.current_company?.id === 80 ? 'sidebar.menuItem.barges' : 'sidebar.menuItem.vehicles',
                type: "nav-item",
                icon: user.current_company?.id === 80 ? <DirectionsBoatTwoToneIcon fontSize="small" color={"primary"} /> : <LocalShippingOutlinedIcon sx={{fontSize: 20}}/>
            });
        } else {
            if (user && user.current_company?.id !== 80) {
                if (out[0].children && !_.includes(_.map(user.profiles, 'name'), 'driver')) {
                    out[0].children.push({
                        uri: "/orders",
                        label: 'sidebar.menuItem.orders',
                        type: "nav-item",
                        icon: <ShoppingBagOutlinedIcon sx={{fontSize: 20}}/>
                    });
                }
            
                out[0].children.push({
                    uri: "/planification",
                    label: 'sidebar.menuItem.planification',
                    type: "nav-item",
                    icon: <GroupWorkOutlinedIcon sx={{fontSize: 20}}/>
                });
            }
        
            if(user && out[0].children && !_.includes(_.map(user.profiles, 'name'), 'driver')){
                out[0].children.push({
                    uri: "/trips",
                    label: 'sidebar.menuItem.trips',
                    type: "nav-item",
                    icon: user.current_company?.id === 80 ? <DirectionsBoatTwoToneIcon fontSize="small" color={"primary"} /> : <LocalShippingOutlinedIcon sx={{fontSize: 20}}/>
                })
                out[0].children.push({
                    uri: "/customers",
                    label: 'sidebar.menuItem.customers',
                    type: "nav-item",
                    icon: <PersonPinCircleOutlinedIcon sx={{fontSize: 20}}/>
                })
                out[0].children.push({
                    uri: "/dashboard",
                    label: 'sidebar.menuItem.dashboard',
                    type: "nav-item",
                    icon: <DashboardOutlinedIcon sx={{fontSize: 20}}/>
                });
                if (user.current_company?.id !== 80) {
                    out[0].children.push({
                        uri: "/task_authorizations",
                        label: 'sidebar.menuItem.task_authorizations',
                        type: "nav-item",
                        icon: <InventoryOutlinedIcon sx={{fontSize: 20}}/>
                    });
                }
            }
        
            if(user && out[0].children && _.includes(_.map(user.profiles, 'name'), 'supervisor')){
                out.push({
                    label: 'sidebar.menu.admin',
                    type: "section",
                    children: [
                        {
                            uri: "/vehicles",
                            label: user.current_company?.id === 80 ? 'sidebar.menuItem.barges' : 'sidebar.menuItem.vehicles',
                            type: "nav-item",
                            icon: user.current_company?.id === 80 ? <DirectionsBoatTwoToneIcon fontSize="small" color={"primary"} /> : <LocalShippingOutlinedIcon sx={{fontSize: 20}}/>
                        },
                        {
                            uri: "/users",
                            label: 'sidebar.menuItem.users',
                            type: "nav-item",
                            icon: <PeopleAltOutlinedIcon sx={{fontSize: 20}}/>
                        },
                    ]
                });
            }
            
            if(user && out[0].children && (_.includes(_.map(user.profiles, 'name'), 'customer_admin') || _.includes(_.map(user.profiles, 'name'), 'superadmin'))){
                out.push({
                    label: 'sidebar.menu.admin',
                    type: "section",
                    children: [
                        {
                            uri: "/vehicles",
                            label: user.current_company?.id === 80 ? 'sidebar.menuItem.barges' : 'sidebar.menuItem.vehicles',
                            type: "nav-item",
                            icon: user.current_company?.id === 80 ? <DirectionsBoatTwoToneIcon fontSize="small" color={"primary"} /> : <LocalShippingOutlinedIcon sx={{fontSize: 20}}/>
                        },
                        {
                            uri: "/plugins",
                            label: 'sidebar.menuItem.plugins',
                            type: "nav-item",
                            icon: <AppsOutlinedIcon sx={{fontSize: 20}}/>
                        },
                        {
                            uri: "/users",
                            label: 'sidebar.menuItem.users',
                            type: "nav-item",
                            icon: <PeopleAltOutlinedIcon sx={{fontSize: 20}}/>
                        },
                        {
                            uri: "/surveys",
                            label: 'sidebar.menuItem.surveys',
                            type: "nav-item",
                            icon: <QuizOutlinedIcon sx={{fontSize: 20}}/>
                        },
                        {
                            uri: "/logs",
                            label: 'sidebar.menuItem.logs',
                            type: "nav-item",
                            icon: <FeaturedPlayListOutlinedIcon sx={{fontSize: 20}}/>
                        },
                    ]
                })
            }
        
            if(user && out[0].children && (_.includes(_.map(user.profiles, 'name'), 'customer_admin') || _.includes(_.map(user.profiles, 'name'), 'superadmin'))){
                out[out.length-1].children.push(
                    {
                        uri: "/company_settings",
                        label: 'sidebar.menuItem.company_settings',
                        type: "nav-item",
                        icon: <SettingsOutlinedIcon sx={{fontSize: 20}}/>
                    },
                )
            }
        }
    }
    return out;
};

export default menus;
