import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography, TableContainer, Table, TableBody, List, Divider, Grid } from "@mui/material";
import _ from "lodash";
import { geoCoords, getAssetPath } from "app/utils/appHelpers";
import Div from "@jumbo/shared/Div";
import { GoogleMap, Marker, Polygon, Polyline, useLoadScript } from "@react-google-maps/api";
import RefreshIcon from '@mui/icons-material/Refresh';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

import tripServices from "app/services/trip-services"
import moment from 'moment';
import { arrayMove, SortableContainer } from 'react-sortable-hoc';
import DeliveryCell from './DeliveryCell';
import { LoadingButton } from '@mui/lab';
import { ASSET_IMAGES } from 'app/utils/constants/paths';
import CustomMarker from '../../widgets/Markers';
import { GOOGLE_MAPS_API_KEY } from 'app/utils/constants/paths';

const GOOGLE_MAPS_LIBS = ["visualization", "places", "drawing"]

// https://loading.io/icon/set/thjmhy-map-marker

const RouteBuilder = props => {
    const [newDeliveries, setNewDeliveries] = useState([])
    const [deliveriesLoading, setDeliveriesLoading] = useState(false)
    const [routeLoading, setRouteLoading] = useState(false)
    const [saving, setSaving] = useState(false)
    const [reloadOnClose, setReloadOnClose] = useState(false)
    const [originLoc, setOriginLoc] = useState(null)
    const [mapCenter, setMapCenter] = useState({
        lat: -33.51392,
        lng: -70.73039,
    })
    const [selectedDelivery, setSelectedDelivery] = useState(null)
    const [canSave, setCanSave] = useState(false)
    const {isLoaded} = useLoadScript({
        googleMapsApiKey: GOOGLE_MAPS_API_KEY,
        libraries: GOOGLE_MAPS_LIBS
    });

    const optionsPoly = {
        strokeColor: "#1867D2",
        fillColor: "#02B0FF",        
        strokeOpacity: 0.8,
        strokeWeight: 4,
        fillOpacity: 1,
        clickable: false,
        draggable: false,
        editable: false,
        visible: true,
        radius: 30000,
        zIndex: 1
    };

    useEffect(() => {
        if(props.trip) {
            loadDetails()
        }
    }, [])

    const loadDetails = () => {
        setDeliveriesLoading(true);
        tripServices.getTripDetails(props.trip?.id, '?with_routes=true&include=deliveries.receipt,deliveries.survey_answers,deliveries.order,deliveries.docs_images_attachments,deliveries.shipping_docs.shipping_doc_kind,deliveries.locatable.location,deliveries.locatable.recipients,deliveries.delivery_details.images_attachments,deliveries.delivery_details.order_detail')
            .then((data) => {
                setNewDeliveries(data?.deliveries)
                setOriginLoc(data?.origin_location)
                if(data?.origin_location) {
                    setMapCenter({
                        lat: data.origin_location.lat,
                        lng: data.origin_location.lng
                    })
                }
                setDeliveriesLoading(false);
            });
    }

    const renderMap = () => {
        var last_customer = null;
        var curr_stop = 0;

        return(
            <React.Fragment>
                { (isLoaded && newDeliveries && newDeliveries[0]?.locatable?.location?.lat) &&
                    <GoogleMap
                        mapContainerStyle={{ width: '100%', minHeight: 400, height: "100%", borderRadius: 12 }}
                        center={mapCenter}
                        zoom={10}>
                        {
                            <React.Fragment>
                                {_.map(newDeliveries, (nd) => {
                                    return(
                                        <Polygon
                                            path={geoCoords(nd.locatable?.location?.path)}
                                            key={nd.code}
                                            options={{
                                                strokeColor: "#FF0000",
                                                strokeOpacity: 0.8,
                                                strokeWeight: 2,
                                                fillColor: "#FF0000",
                                                fillOpacity: 0.35
                                            }}
                                        />
                                    )
                                })}
                                if(originLoc) {
                                    <Marker
                                        icon={getAssetPath(`${ASSET_IMAGES}/map-markers/base.png`)}
                                        position={{ lat: originLoc?.lat, lng: originLoc?.lng }}
                                    />
                                }
                                {/* <Polygon
                                    path={geoCoords(nd.locatable?.location?.path)}
                                    key={nd.code}
                                    options={{
                                        strokeColor: "#FF0000",
                                        strokeOpacity: 0.8,
                                        strokeWeight: 2,
                                        fillColor: "#FF0000",
                                        fillOpacity: 0.35
                                    }}
                                /> */}
                                {_.map(newDeliveries, (nd) => {
                                    if(last_customer != nd.locatable?.id) {
                                        last_customer = nd.locatable?.id
                                        curr_stop += 1
                                        return(
                                            <React.Fragment>
                                                <CustomMarker
                                                    id={nd.id}
                                                    lat={nd.locatable?.location?.lat}
                                                    lng={nd.locatable?.location?.lng}
                                                    kind={nd.state}
                                                    // labelAnchor={{ x: 40 / 2, y: 80 }}
                                                    label={{
                                                        text: `${curr_stop}`,
                                                        color: 'white',
                                                        textAlign: 'center',
                                                        fontWeight: 'bold',
                                                        anchor: { x: 0, y: 40 }
                                                    }}
                                                    zIndex={100} />
                                                <Polyline
                                                    // onLoad={onLoadPoly}
                                                    path={nd.route_path}
                                                    options={optionsPoly}
                                                    />
                                                {nd.receipt && nd.receipt?.lat && nd.receipt?.lng &&
                                                    <Marker
                                                        label={{
                                                            text: `${curr_stop}`,
                                                            color: 'white',
                                                            textAlign: 'center',
                                                            fontWeight: 'bold',
                                                            anchor: { x: (20/2) , y: 80 }
                                                        }}
                                                        position={{lat: _.toNumber(nd.receipt.lat), lng: _.toNumber(nd.receipt.lng)}}
                                                    />
                                                }
                                            </React.Fragment>
                                        )
                                    }
                                })}
                            </React.Fragment>
                        }
                    </GoogleMap>
                }
            </React.Fragment>
        )

    }

    const calculateRoute = () => {
        setRouteLoading(true)
        _.map(newDeliveries, (d) => {})
        tripServices.calculateRouteOrder(props.trip?.id)
                .then((data) => {
                    setMapCenter({lat: data.origin_location.lat, lng: data.origin_location.lng})
                    setNewDeliveries(data.deliveries)
                    setRouteLoading(false)
                })
                .catch((err) => {
                    setRouteLoading(false)
                });
    }
    const saveRoute = () => {
        setSaving(true);
        
        let par = { include: "driver,vehicle.company,vehicle.transport_unit1,vehicle.transport_unit2,vehicle.centre.main_centre_zone,deliveries.receipt,deliveries.survey_answers.survey_question,deliveries.order,deliveries.docs_images_attachments,deliveries.locatable.location,deliveries.delivery_details.images_attachments,deliveries.delivery_details.order_detail,deliveries.delivery_docs.shipping_doc.shipping_doc_kind", custom_order: _.map(newDeliveries, 'id') }
        tripServices.calculateRouteOrder(props.trip?.id, par)
                .then((data) => {
                    setSaving(false)
                })
                .catch((err) => {
                    setSaving(false)
                });
    }

    const onSortEnd = ({oldIndex, newIndex}) => {
        setNewDeliveries(arrayMove(newDeliveries, oldIndex, newIndex));
    };

    const handleDeliverySelect = (delivery) => {
        if(delivery.locatable && delivery.locatable.location) {
            setMapCenter({lat: delivery.locatable.location.lat, lng: delivery.locatable.location.lng})
        }
    }

    return (
        <React.Fragment>
            <Dialog
                fullWidth={true}
                maxWidth={"xl"}
                open={props.isOpen}
                onClose={() => { props.onClose(reloadOnClose) }}>
                <DialogTitle>
                    <Div sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1}}>
                        <Typography variant={"h2"} color="primary">Instructivo Nº{props.trip?.code} ({moment(props.trip?.ship_date).format('DD MMM')})</Typography>
                        <LoadingButton
                            loading={routeLoading}
                            variant="contained"
                            color='success'
                            size='small'
                            endIcon={<RefreshIcon/>}
                            loadingPosition="end"
                            onClick={() => calculateRoute()}>
                                { routeLoading ? 'Calculando' : 'Optimizar' }
                        </LoadingButton>
                    </Div>
                </DialogTitle>
                <DialogContent>
                    {deliveriesLoading ?
                        <Div sx={{textAlign: 'center', my: 5}}>
                            <CircularProgress color="warning"/>
                        </Div>
                    :
                        <React.Fragment>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={5}>
                                    {renderMap()}
                                </Grid>
                                <Grid item xs={12} md={7}>
                                    <Typography variant={"h4"} sx={{mb: 0}}>Planificación de entregas</Typography>
                                    <Typography variant={"caption"} color={"text.secondary"}>Arrastra y suelta las entregas para reordenarlas</Typography>
                                    <Deliveries
                                        tripId={props.trip?.id}
                                        deliveries={newDeliveries}
                                        onSortEnd={onSortEnd}
                                        distance={1}
                                        // useDragHandle={true}
                                        _selectedDelivery={selectedDelivery}
                                        canSort={props.trip?.state === 'pending'}
                                        onSelect={handleDeliverySelect}
                                        lockAxis={'y'}
                                        onAdd={(d) => { setReloadOnClose(true); loadDetails() }}
                                        onRemove={(d) => { setReloadOnClose(true); loadDetails() }} />
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setCanSave(false); props.onClose(reloadOnClose) } }>Cerrar</Button>
                    <LoadingButton
                        loading={saving}
                        variant="contained"
                        color={ canSave ? 'success' : 'primary' }
                        size='small'
                        endIcon={<SaveOutlinedIcon/>}
                        loadingPosition="end"
                        onClick={() => saveRoute()}>
                            { saving ? 'Guardando' : 'Guardar' }
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

const Deliveries = SortableContainer(({ tripId, deliveries, _selectedDelivery, canSort = false, onSelect, onAdd, onRemove }) => {
    var last_customer = null;
    var curr_stop = 0;
    return (
        <List
            dense={true}
            sx={{ padding: 0 }}>
                <DeliveryCell
                    formNew={true}
                    disabled={!canSort}
                    onAdd={onAdd}
                    tripId={tripId} />
                    
                {deliveries?.map((delivery, index) => {
                    if(last_customer !== delivery.locatable?.id) {
                        last_customer = delivery.locatable?.id
                        curr_stop += 1
                    }
                    return (
                        <DeliveryCell
                            key={delivery.code}
                            index={index}
                            delivery={delivery}
                            selected={_selectedDelivery?.id === delivery.id}
                            canSort={canSort}
                            disabled={!canSort}
                            onSelect={onSelect}
                            stop={curr_stop}
                            onRemove={onRemove}
                            tripId={tripId}
                            itemStyle={{
                                borderTop: '1px solid #EEE',
                            }} />
                    )
                })}
        </List>
    )
})

export default RouteBuilder;